import React from 'react';
import classNames from 'classnames';
import t, { _t } from '../../utils/translate';
import { useSpring, animated, config } from 'react-spring';

import styles from './FrontpageHeader.module.scss';
import ImageOrVideo from '../ImageOrVideo/ImageOrVideo';

export default function FrontpageHeader({ title, intro, asset }) {
  const headline = { __html: _t(title) };
  const headProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0px)' },
    config: config.slow,
  });
  const subProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 50px, 0px)' },
    config: config.slow,
    delay: 300,
  });

  return (
    <div className={styles.fpHeaderOuter}>
      <div className={styles.fpHeaderInner}>
        <div className={styles.textOuter}>
          <div className={styles.textInner}>
            <animated.h1
              style={headProps}
              className="headline-100"
              dangerouslySetInnerHTML={headline}
            />
            <animated.div style={subProps} className={styles.introOuter}>
              <p className={classNames(styles.intro)}>{_t(intro)}</p>
            </animated.div>
          </div>
        </div>
        <animated.div style={headProps} className={styles.assetOuter}>
          {asset.map((content, index) => {
            return <ImageOrVideo key={index} content={content} />;
          })}
        </animated.div>
        <animated.div style={subProps} className={styles.fpTicketOuter}>
          <a href={t('ticketUrl')} className={styles.fpTicket}>
            <p>{t('fpTicket')}</p>
          </a>
        </animated.div>
      </div>
    </div>
  );
}
