import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import FrontpageHeader from '../components/FrontpageHeader/FrontpageHeader';
import Shortcuts from '../components/Shortcuts/Shortcuts';
import SEO from '../components/seo';
import useSetLocale from '../hooks/useSetLocale';

const Home = ({ data, location }) => {
  useSetLocale();

  let {
    title,
    intro,
    imageOrVideo,
    frontpageContent,
    seo,
  } = data.sanityFrontpage;

  return (
    <Layout location={location}>
      <SEO
        description={seo && seo.description ? seo.description : null}
        image={seo && seo.image ? seo.image.asset.fixed.src : null}
      />
      <FrontpageHeader title={title} intro={intro} asset={imageOrVideo} />
      <div className="page-padding">
        {frontpageContent && (
          <>
            <Shortcuts shortcuts={frontpageContent} />
            <TwoColumns location={location} contents={frontpageContent} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    sanityFrontpage {
      title {
        nb
        en
        _type
      }
      intro {
        nb
        en
        _type
      }
      imageOrVideo {
        ... on SanityVideoUpload {
          ...HdVideoQuery
        }
        ... on SanityMediaImage {
          ...HeadImageQuery
        }
      }
      frontpageContent {
        ... on SanitySubpageVisit {
          ...VisitPageQuery
        }
        ... on SanitySubpageExperiences {
          ...ExperiencesPageQuery
        }
        ... on SanitySubpageEvents {
          ...EventPageQuery
        }
        ... on SanityInfoBlocks {
          ...SanityInfoBlockQuery
        }
      }
      seo {
        ...SeoQuery
      }
    }
  }
`;
